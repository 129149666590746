@import "brandcolor";

$font-family-sans-serif:       Roboto, Arial,  sans-serif, !default;
$font-family:            $font-family-sans-serif !default;
// stylelint-enable value-keyword-case

$font-size-base:              1rem !default; // Assumes the browser default, typically `16px`
$font-size-lg:                $font-size-base * 1.25 !default;
$font-size-sm:                $font-size-base * .875 !default;

$font-weight-lighter:         lighter !default;
$font-weight-light:           300 !default;
$fontmedium: 500;
$font-weight-normal:          400 !default;
$font-weight-bold:            700 !default;
$font-weight-bolder:          bold !default;

$font-weight-base:            $font-weight-normal !default;

$spacer: 1rem !default;


$headings-margin-bottom:      15px;
$headings-font-family:        $font-family !default;
$headings-font-weight:        500 !default;
$headings-line-height:        1.2 !default;
$headings-color:              null !default;

h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
  margin:0px;
  margin-bottom: $headings-margin-bottom;
  font-family: $headings-font-family;
  font-weight: $headings-font-weight;
  line-height: $headings-line-height;
  color: $headings-color;
}

$h1-font-size:26px !default;
$h2-font-size: 28px !default;
$h3-font-size: 24px !default;
$h4-font-size: 20px !default;             
$h5-font-size: 18px    !default;          
$h6-font-size: 16px !default;


body{font-family: $font-family;}
/*Font Size's*/
$bodyfont:$font-size-base;
$font12:12px;
$font14:14px;
$font16:16px;
$font18:18px;
$font20:20px;
$largefont:60px;
$fontinherit: inherit;

/* Margin Variables*/
$margin0:0px;
$margin5:5px;
$margin30:30px;
$margin-auto:auto;
$margin-bottom10:10px;
$margin-bottom20:20px;
$margin-bottom30:30px;

/*Width*/
$width-full:100%;
$width-half:50%;
$width-auto:auto;

$height-full:100%;
$height-auto:auto;

/* Padding Variables*/
$padding5:5px;
$padding0:0px;
$padding20:20px;
$padding30:30px;

/* Float's*/
$float-left:left;
$float-right:right;
$float-none:none;

/* Text Aligment's*/
$text-center:center;
$text-right:right;
$text-left:left;
$text-justify:justify;

/* Text Decoration*/
$text-decoration-none: none;
$text-decoration-underline: underline;

/* Display */
$display-none:none;
$display-block:block; 
$display-inline:inline;
$display-inline-block:inline-block;
$display-inherit:inherit;
$display-table:table;
$display-table-cell:table-cell;
/* Opacity */
$opacity1:1;
$opacity0:0;
$opacity60:0.6;

/* List Style none */
$list-style-none:none;

/* Font Weight*/
$font-weight-bold:bold;
$font-weight-normal:normal;

/* Cursor*/
$cursor-pointer: pointer;
$cursor-disabled: disabled;

/* Position  */
$position-absolute:absolute;
$position-relative:relative;
$position-fixed:fixed;
$position-static:static;

$line-height-base:        1.428571429 !default; // 20/14
//** Computed "line-height" (`font-size` * `line-height`) for use with `margin`, `padding`, etc.
$line-height-computed:    floor(($bodyfont * $line-height-base)) !default; // ~20px
// $rupeesImgUrl: url(../images/rupees-icons.png);
// $rupeesImgUrl2x: url(../images/rupees-icons-2x.png);
$rupeesImgUrl: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' version='1.1' ><text opacity='0.8' font-size='15' x='3' y='13' >Rp</text></svg>");
$rupeesImgUrl2x: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' version='1.1' ><text opacity='0.8' font-size='15' x='3' y='13' >Rp</text></svg>");

//Form
$input-color: $black-87;

$overflow-hidden:hidden;

$btn-border-width:1px;
$btn-font-family:$font-family;
$btn-font-weight:400;
$btn-box-shadow: inset 0 1px 0 rgba($white, .15), 0 1px 1px rgba($black, .075) !default;

$caret-width:                 .3em !default;
$caret-vertical-align:        $caret-width * .85 !default;
$caret-spacing:               $caret-width * .85 !default;
$enable-caret:                true !default;
$uppercaselink: uppercase;

/*  modal */
$zindex-modal:999;
$zindex-modal-backdrop:998;

$modal-xl:                          1140px !default;
$modal-lg:                          800px !default;
$modal-md:                          500px !default;
$modal-sm:                          300px !default;

$modal-fade-transform:              translate(0, -50px) !default;
$modal-show-transform:              none !default;
$modal-transition:                  transform .3s ease-out !default;
$modal-dialog-margin:               .5rem !default;

/* break point and media */
$retina: "(-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi)";
$breakpoint-mob1: 576px;
$breakpoint-tab1: 768px;
$breakpoint-tab2: 992px;
$breakpoint-pc1: 1200px;
$breakpoint-pc2: 1400px;

/* Tooltips*/
$tooltip-bg:                        $black !default;
$tooltip-max-width:                 200px !default;
$tooltip-color:                     $white !default;
$zindex-tooltip :1070;
$tooltip-margin: 0;
$tooltip-font-size: 12px;
$tooltip-opacity:0.9;
$tooltip-arrow-width:               .8rem !default;
$tooltip-arrow-height:              .4rem !default;
$tooltip-arrow-color:               $tooltip-bg !default;

/* popover*/
$zindex-popover: 99;
$popover-max-width:300px;
$popover-font-size:$font14;
$popover-border-color:              rgba($black, .2) !default;
$popover-box-shadow :0px 3px 8px 0px rgba(0,0,0,0.3);
$popover-arrow-width:               1rem !default;
$popover-arrow-height:              .5rem !default;
$popover-arrow-color:               $white !default;
$popover-arrow-margin: 0.3rem;
$popover-border-width:              1px !default;
$popover-arrow-outer-color:         fade-in($popover-border-color, .05) !default;
$popover-border-radius:5px;

/*Dashboard*/
$dashcardbg : #E3EAEF;

/* visibility  */
$visibility-hidden:hidden;
$visibility-visible:visible;
/* Cursor*/
$cursor-pointer: pointer;
$cursor-default: default;
$cursor-pointer-none: none;
$cursor-text: text;
$cursor-inherit:inherit;
$pointer-events-none: none;
$cursor-not-allowed:not-allowed;